// src/components/TableOfContents.tsx
import React from 'react';
import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { useAspectRatio } from '../AspectRatioContext';

// Define the background color variable
const bgColor = 'cream.darker';

// List of sections
const sections = ['Sydney', 'Portfolio', 'Services', 'Contact'];

const TableOfContents: React.FC = () => {
  const theme = useTheme(); // Access theme
  const aspectRatio = useAspectRatio();
  const isLandscape = aspectRatio >= 1; // Determine if layout is landscape

  // Utility function to generate lines between sections
  const renderLine = (isLandscape: boolean, maxLength: number) => {
    return isLandscape ? (
      <Box
        width="2px" // Set small width to make it vertical
        height="50px" // Adjust height to ensure visibility (vertical line)
        bg={theme.colors.graphite.default} // Line color
        mx={2} // Add margin to center the line between sections
      />
    ) : (
      <Box
        height="2px" // Horizontal line in vertical layout
        width={`${maxLength * 3}ch`} // Adjust width to match the longest section
        bg={theme.colors.graphite.default} // Line color
        my={2} // Add vertical margin for spacing
      />
    );
  };

  // Find the longest section name to calculate the width for vertical lines
  const longestSection = Math.max(...sections.map(section => section.length));

  return (
    <Box p={4}>
      <Flex
        direction={isLandscape ? 'row' : 'column'} // Set direction based on isLandscape
        justifyContent={isLandscape ? 'space-around' : 'center'}
        alignItems="center"
        textAlign="center"
        my={isLandscape ? '4%' : '4%'}
      >
        {sections.map((section, index) => (
          <React.Fragment key={section}>
            {index > 0 && renderLine(isLandscape, longestSection)}
            <Text variant="infoText" m={isLandscape ? '0 2%' : '4% 0'}>
              {section}
            </Text>
          </React.Fragment>
        ))}
      </Flex>
    </Box>
  );
};

export default TableOfContents;
