// src/themes/text.ts
const text = {
  variants: {
    siteTitleText: {
      fontSize: '4xl',
      color: 'graphite.default',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    titleText: {
      fontSize: '3xl',
      fontWeight: 'bold',
      color: 'graphite.default',
    },
    infoText: {
      fontSize: 'l',
      color: 'graphite.default',
    },
  },
};
  
export default text;
