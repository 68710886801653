// src/components/Navbar.tsx
import React from 'react';
import { Box, Button, Flex, Text, useTheme } from '@chakra-ui/react';
import { useAspectRatio } from '../AspectRatioContext';

const Navbar: React.FC<{ scrollToSection: (sectionId: string) => void }> = ({
  scrollToSection,
}) => {
  const theme = useTheme(); // Access the theme
  const aspectRatio = useAspectRatio();
  const isLandscape = aspectRatio >= 1;

  const handleButtonClick = (sectionId: string) => {
    scrollToSection(sectionId);
  };

  // Define navButton functionality using infoTextColor and infoTextHighlightColor
  const navButton = (text: string, onClick: () => void) => {
    return (
      <Button
        onClick={onClick}
        bg="transparent" // Background is transparent
        color={theme.infoTextColor} // Default color is infoTextColor
        _hover={{
          bg: 'transparent',  // Keep background transparent on hover
          color: theme.infoTextHighlightColor,  // Change text color to infoTextHighlightColor on hover
        }}
      >
        <Text variant="infoText">{text}</Text>
      </Button>
    );
  };

  return (
    <Box>
      <Flex as="nav" alignItems="center" justifyContent="space-around" py={6}>
        {isLandscape && navButton('SYDNEY', () => handleButtonClick('sydney'))}
        {isLandscape && navButton('PORTFOLIO', () => handleButtonClick('portfolio'))}
        <Text variant="siteTitleText">SYDNEY LYNN</Text>
        {isLandscape && navButton('SERVICES', () => handleButtonClick('services'))}
        {isLandscape && navButton('CONTACT', () => handleButtonClick('contact'))}
      </Flex>
    </Box>
  );
};

export default Navbar;
