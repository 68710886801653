import React, { useRef, useEffect, useState } from 'react';
import { Box, Text, Grid, Image, useMediaQuery } from '@chakra-ui/react';
import lawnMaintenanceImage from '../assets/icons/camera.png';
import landscapeMaintenanceImage from '../assets/icons/camera.png';
import landscapeDesignBuildImage from '../assets/icons/camera.png';
import fullPropertyMaintenanceImage from '../assets/icons/camera.png';

type ServiceCardProps = {
  title: string;
  image: string;
  description: string;
};

const ServiceCard: React.FC<ServiceCardProps> = ({ title, image, description }) => {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeText = () => {
      if (textRef.current) {
        const textWidth = textRef.current.offsetWidth;
        const containerWidth = textRef.current.parentElement?.offsetWidth || 0;
        const maxWidth = containerWidth * 0.8; // 80% of the container width

        if (textWidth > maxWidth) {
          const fontSize = (maxWidth / textWidth) * parseFloat(getComputedStyle(textRef.current).fontSize);
          textRef.current.style.fontSize = `${fontSize}px`;
        }
      }
    };

    window.addEventListener('resize', resizeText);
    resizeText();

    return () => {
      window.removeEventListener('resize', resizeText);
    };
  }, []);

  return (
    <Box 
      position="relative"
      width="70%"
      borderColor="quaternary.bg"
      borderWidth="4px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mx="auto"
    >
      <Image 
        src={image} 
        alt={title}
        width="55%" 
        height="55%" 
        objectFit="contain"
        marginLeft="auto"
        marginRight="auto"
        mt="3%"
        mb="3%"
      />
      <Box 
        width="80%" 
        borderBottomWidth="4px" 
        borderBottomColor="quaternary.bg" 
        mx="10%" 
        mt="3%" 
      />
      <Text
        ref={textRef}
        textAlign="center" 
        color="quaternary.bg" 
        mt="3%"
        fontWeight="bold"
        fontSize="2.5rem"
        mb="3%"
        maxW="80%"
      >
        {title}
      </Text>
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="quaternary.bg"
        opacity="0"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex="10"  // Add z-index to overlay text on top
        _hover={{ opacity: '1' }} // Make text fully opaque when hovered
      >
        <Text 
          as="h3"
          textAlign="center"
          color="secondary.text" 
          mt="2%"
          fontWeight="bold"
          fontSize="clamp(2rem, 4vw, 3rem)"
        >
          {title}
        </Text>
        <Box 
          width="80%" 
          borderBottomWidth="4px" 
          borderBottomColor="secondary.text" 
          mx="10%" 
          mt="2%"
        />
        <Text color="secondary.text" p={5} textAlign="center" fontSize="clamp(1rem, 3vw, 2rem)">
          {description.split("\n").map((str, index, array) => 
            array.length - 1 === index ? str : <>
              {str}<br/>
            </>
          )}
        </Text>
      </Box>
    </Box>
  );
};

const Services: React.FC = () => {
  const services = [
    { 
      title: 'Lawn Maintenance', 
      image: lawnMaintenanceImage, 
      description: "Weekly Mowing\nFall Leaf Cleanup\nTrimming and Edging\nCommercial and Residential" 
    },
    { 
      title: 'Landscape Maintenance', 
      image: landscapeMaintenanceImage, 
      description: "Edging\nMulch\nBush Trimming\nWeeding and Cleanup" 
    },
    { 
      title: 'Landscape Design/Build', 
      image: landscapeDesignBuildImage, 
      description: "Designs From Scratch\nInstallation of New Designs\nCustom to Your Needs\nSeamless Integration" 
    },
    { 
      title: 'Full Property Maintenance', 
      image: fullPropertyMaintenanceImage, 
      description: "Insert Description Here\nInsert Description Here\nInsert Description Here\nInsert Description Here" 
    },
  ];

  const [isWide] = useMediaQuery("(min-width: 1000px)");

  return (
    <Box 
      id="services"
      maxW="1000px"
      mx="auto"
      bg="tertiary.bg" 
      pt="2%" 
      pb="5%"
      alignItems="center"
      justifyContent="center"
    >
      <Text as="h2" fontSize="clamp(3rem, 10vw, 6rem)" fontWeight="bold" fontFamily="architectural" color="quaternary.bg" textAlign="center">
         Services
      </Text>
      <Grid 
        templateColumns={isWide ? "1fr 1fr" : "1fr"}
        gap={6}
        mt={8}
        alignItems="center"
        justifyContent="center"
      >
      {services.map((service, index) => (
        <ServiceCard key={index} {...service} />
      ))}
      </Grid>
    </Box>
  );
};

export default Services;
