// src/themes/library/color.ts
const color = {
    purple: {
      default: '#8A3463',
      dark: '#483248',
      light: '#E6E6FA',
    },
    cinammon: {
      lighter: '#ece0d1',
      light: '#dbc1ac',
      default: '#967259',
      dark: '#634832',
      darker: '#38220f',
    },
    green: {
      artichoke: '#8F9779',
      darkJungle: '#1A2421',
      gin: '#D8E4BC',
      chetwode: '#F0FFF0',
      sage: '#9DC183',
      sea: '#2E8B57',
      darkMoss: '#4A5D23',
      laurel: '#A9BA9D',
    },
    cream: {
      lighter: '#f9f9f7',
      light: '#f4f3ef',
      default: '#efeee8',
      dark: '#eae8e0',
      darker: '#e5e3d9',
    },
    graphite: {
      default: '#373839',
    },
  };
  
  export default color;
  