// src/themes/library/font.ts
import '@fontsource/kalam';
import '@fontsource/major-mono-display';
import '@fontsource/ysabeau-office';
import '@fontsource/playfair-display';
import '@fontsource/cormorant-garamond';
import '@fontsource/cormorant';
import '@fontsource/nanum-myeongjo';
import '@fontsource/zen-old-mincho';
import '@fontsource/raleway';

const font = {
  body: 'Cormorant Garamond, sans-serif',
  heading: 'Cormorant Garamond, sans-serif',
};

export default font;
