import React from 'react';
import { Box, Text, Center, Flex, Image, useTheme } from '@chakra-ui/react';


import sydney1 from '../assets/images_syd/sydney1.jpg';
import sydney2 from '../assets/images_syd/sydney2.jpg';

type PersonCardProps = {
  name: string;
  picture: string;
  description: string;
};

const PersonCard: React.FC<PersonCardProps> = ({ name, picture, description }) => {
  const theme = useTheme();

  return (
    <Box 
      position="relative" 
      textAlign="center"
      width="80%"
      aspectRatio={1}
    >
      <Box
        position="relative"
        width="98%" 
        height="98%"
        margin="1%"
      >
        <Image 
          src={picture} 
          alt={name} 
          width="100%" 
          height="100%" 
          objectFit="cover"
        />
      </Box>
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="cinammon.light"
        opacity="0"
        color="cinammon.light"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        _hover={{ opacity: '1' }}
      >
        <Text 
          as="h3"
          fontSize="clamp(3rem, 5vw, 4rem)"
          justifyContent="center"
          color="cinammon.dark" 
          mt="2%"
          fontWeight="bold"
        >
          {name}
        </Text>
        <Box 
          width="80%" 
          borderBottomWidth="4px" 
          borderBottomColor="cinammon.dark" 
          mx="10%" 
          mt="2%" 
        />
        <Text color="cinammon.dark" fontSize="clamp(1rem, 3vw, 2rem)">{description}</Text>
      </Box>
    </Box>
  );
};

const Sydney: React.FC = () => {
    const team = [
      { name: 'Sydney 1', picture: sydney1, description: 'Lorem ipsum dolor sit amet' },
      { name: 'Sydney 2', picture: sydney2, description: 'Sed do eiusmod tempor incididunt' },
    ];
  

  return (
    <Box 
      id="sydney"
      p={4}
      bg="cream.darker"
    >
      <Text variant="titleText" textAlign="left">
        Sydney
      </Text>
      <Box mx="2%">
        <Flex alignItems="center" width="100%">
        </Flex>
        <Box py={4} color="cinammon.light" mx="10%">
          <Center mt={4} mb={4}>
            <Text variant="infoText" textAlign="justify">
              Sydney is really really really really really really really really really really really really really really really really really really really really really really really really really really really really really really really really cool.
            </Text>
          </Center>
          <Flex flexDirection="column" alignItems="center" justifyContent="center" mt={8}>
            <PersonCard {...team[0]} />
            <Box my="3%"/>
            <PersonCard {...team[1]} />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Sydney;
