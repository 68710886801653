import React, { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './themes/theme';
import Navbar from './components/Navbar';
import MainPhoto from './components/MainPhoto';
import TableOfContents from './components/TableOfContents'; // Import TableOfContents
import Gallery from './components/Gallery';
import Sydney from './components/Sydney';
import Services from './components/Services';
import { AspectRatioProvider } from './AspectRatioContext';
import { getFolders, ImageItem } from './utils/getImages';

const App: React.FC = () => {
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop,
      });
    }
  };

  const [folders, setFolders] = useState<ImageItem[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const folders = await getFolders();
        setFolders(folders);
      } catch (err) {
        setError('Failed to fetch folders');
        console.error(err);
      }
    };
    fetchFolders();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <AspectRatioProvider>
        <div>
          <Navbar scrollToSection={scrollToSection} />
          <MainPhoto />
          <TableOfContents />
          <Sydney />
          {error ? (
            <div>{error}</div>
          ) : (
            <Gallery images={folders} />
          )}
          <Services />
        </div>
      </AspectRatioProvider>
    </ChakraProvider>
  );
};

export default App;
