import { extendTheme } from '@chakra-ui/react';
import color from './library/color';  // Updated to color.ts and default export
import font from './library/font';    // Updated to font.ts and default export
import text from './library/text';    // Import text variants

// Define variables for color customizations
const siteTitleTextColor = color.cinammon.darker;
const infoTextColor = color.cinammon.darker;
const infoTextHighlightColor = color.cinammon.dark;
const titleTextColor = color.graphite.default;
const background = color.cream.lighter;

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        margin: '0',
        padding: '0',
        fontFamily: 'serif',  // Set global font
        bg: background,
        height: '100%',
      },
    },
  },
  colors: color,   // Use the imported colors
  fonts: font,     // Use the imported fonts
  components: {
    Text: {
      variants: {
        siteTitleText: {
          ...text.variants.siteTitleText,  // Inherit the existing styles
          color: siteTitleTextColor,       // Only override the color
          fontFamily: 'serif',
        },
        titleText: {
          ...text.variants.titleText,      // Inherit the existing styles
          color: titleTextColor,           // Only override the color
        },
        infoText: {
          ...text.variants.infoText,       // Inherit the existing styles
          color: infoTextColor,
          fontFamily: 'raleway'
        },
      },
    },
  },
});

export default theme;
